@charset "UTF-8";

/* Google Fonts - Bricolage Grotesque, Onest & Homemade Apple */
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200;12..96,300;12..96,400;12..96,500;12..96,600;12..96,700;12..96,800&family=Special+Elite&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Onest:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Homemade+Apple&display=swap');

/* Colours */
:root {
  --primary-color-500: #3446C6;
  --primary-color-600: #2C3BA6;
  --secondary-color: #B3C7FA;

  --card-bg-green: #D7E19E;
  --card-text-green: #104D45;
  --card-bg-brown: #D1CDC1;
  --card-text-brown: #383734;
  --card-bg-orange: #DF8268;
  --card-text-orange: #602718;
  --card-bg-purple: #D69AE5;
  --card-text-purple: #5D2D96;
  --card-bg-yellow: #F3C357;
  --card-text-yellow: #423518;
  --card-bg-blue: #B8CFE5;

  --yellow: #F3C357;

  --light-bg-60: rgba(244, 238, 224, 0.6);
  --light-bg-80: rgba(244, 238, 224, 0.8);
  --light-bg-100: #F4EEE0;
  --light-border: 1px solid rgba(255, 253, 253, 0.6);
  --white: #FFF;
  --off-white: #F9F5ED;
  --transparent-20: rgba(255, 255, 255, 0.2);
  --transparent-40: rgba(255, 255, 255, 0.4);
  --transparent-60: rgba(255, 255, 255, 0.6);
  --transparent-100: rgba(255, 255, 255, 0);

  --blob-peach: #FFDAD2;
  --blob-pink: #EBADD9;
  --blob-blue: #D8EAF9;
  --blur-amount: clamp(2em, 5vw, 6em);
  --blur-1-size: clamp(12em, 60vw, 60vw);
  --blur-2-size: clamp(20em, 40vw, 40vw);
  --blur-3-size: clamp(12em, 10vw, 10vw);

  --transition: all 0.3s ease-in-out;

  --shadow: 0 0.8em 1em rgba(16, 24, 40, 0.04), -0.2em 0.5em 0.4em rgba(16, 24, 40, 0.04);
}

 /* Dark Theme - overide colors in dark theme */
 body.dark-theme {
  --primary-color: rgba(255, 255, 255, 0.9);
  --secondary-color-500: #D69AE5;
  --secondary-color-600: #D283E5;

  --light-bg-60: rgba(10, 13, 38, 0.6);
  --light-bg-80: rgba(10, 13, 38, 0.8);
  --light-bg-100: #0A0D26;
  --light-border: 1px solid rgba(255, 255, 255, 0.06);
  --transparent-20: rgba(10, 13, 38, 0.5);
  --transparent-40: rgba(10, 13, 38, 0.3);
  --transparent-60: rgba(10, 13, 38, 0.8);

  --card-bg-green: #808A70;
  --card-bg-brown: #6B6875;
  --card-bg-orange: #9E645F;
  --card-bg-purple: #6C527F;
  --card-bg-yellow: #7E735A;
  --card-bg-blue: #5A7691;

  --blob-peach: #1F1C55;
  --blob-pink: #212777;
  --blob-blue: #2B1E4F;
}

*,
html,
body {
  box-sizing: border-box;
  font-family: "Onest", sans-serif;
  line-height: 1.5;
}

/* BG blur - light & dark theme */
body {
  background: var(--light-bg-100) !important;
  color: var(--primary-color-500) !important;
}
body.dark-theme {
  background: var(--light-bg-100);
  color: var(--primary-color) !important;
}

/* All links - light & dark theme */
a {
  color: var(--primary-color-500);
  text-decoration: none !important;
}
body.dark-theme a {
  color: var(--primary-color) !important;
}

/* Polaroid Text - always primary color */
.polaroids {
  color: var(--primary-color-500) !important;
}

main {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 6.5em;
  max-width: 1160px;
  padding-top: 12em;
  margin: auto;
  transition:
    flex-direction 0.3s ease-in-out, 
    max-width 0.3s ease-in-out,
    padding-top 0.3s ease-in-out,
    margin 0.3s ease-in-out;
  animation: fade 0.8s ease-in-out;
}

/* Entrance animation */
@keyframes fade {
  0% { 
    opacity: 0;
    transform: translateY(-2em);
  }
}

/* Highlight
----------------------------------- */
::selection, 
::-moz-selection {
  color: white;
  background: var(--primary-color-500);
}

/* Sparkles 
----------------------------------- */
img[alt="Sparkle"] {
  width: 18px;
}

.name-title img[alt="Sparkle"] {
  width: 20px;
}

.footer-sparkle {
  width: 16px;
}

/* Blobs
----------------------------------- */
.blur {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 55em;
}

/* Peach Blob */
.blur:nth-of-type(1) {
  background: var(--blob-peach);
  filter: blur(var(--blur-amount));
  height: var(--blur-1-size);
  width: calc(var(--blur-1-size) * 0.75);
  top: 20%;
  left: 70%;
  transition: 
    height 0.3s ease-in-out,
    width 0.3s ease-in-out,
    top 0.3s ease-in-out, 
    left 0.3s ease-in-out;
}

/* Blue Blob */
.blur:nth-of-type(2) {
  background: var(--blob-blue);
  filter: blur(var(--blur-amount));
  height: var(--blur-2-size);
  width: calc(var(--blur-2-size) * 1.1);
  top: 50%;
  left: 30%;
  transition:
    height 0.3s ease-in-out,
    width 0.3s ease-in-out,
    top 0.3s ease-in-out, 
    left 0.3s ease-in-out;
}

/* Pink Blob */
.blur:nth-of-type(3) {
  background: var(--blob-pink);
  filter: blur(var(--blur-amount));
  height: var(--blur-3-size);
  width: calc(var(--blur-3-size) * 2);
  top: 50%;
  left: 60%;
  transition: 
    height 0.3s ease-in-out,
    width 0.3s ease-in-out,
    top 0.3s ease-in-out, 
    left 0.3s ease-in-out;
}

/* Progress Bar
----------------------------------- */
/* .page-bar-container {
  width: 100%;
  height: 5px;
  top: 0;
}

.page-bar {
  width: 0%;
  height: 5px;
  background-color: var(--primary-color-500);
  transition: background-color 0s ease;
} */

/* Navbar
----------------------------------- */
.fixed-nav {
  position: fixed;
  width: 100%;
  z-index: 100;
  transition: none;
  padding-top: 0;
  padding-bottom: 0;
  animation: show 0s ease-in;
}

@keyframes show {
  0% { 
    opacity: 0;
  }
}

.fixed-nav nav > div:nth-last-of-type(1){
  justify-content: space-between;
  padding: 1.4em 2em;
}

.scrolled {
  transition: background-color 1s ease-in-out;
  background-color: var(--light-bg-60);
  backdrop-filter: blur(20px) saturate(1.2);
}

.logo img {
  height: 40px;
}

.nav-links {
  display: flex;
  gap: 0.6em;
}

/* Desktop Links */
#desktop-links {
  display: flex;
  border-radius: 3em;
  gap: 0.3em;
  margin-left: 10px;
}
#desktop-links .menu-link {
  cursor: pointer;
  color: var(--primary-color-500) !important;
  border-radius: 3em;
  padding: 8px 20px 6px !important;
  transition: none;
}
.theme-icon {
  cursor: pointer;
  border-radius: 3em;
  padding: 10px !important;
  height: 40px;
  transition: none;
}
#desktop-links .menu-link:hover,
#desktop-links img:hover {
  background-color: var(--transparent-60);
  padding: 8px 20px 6px;
  transition: background-color 0.3s ease-in-out;
}

/* Mobile Links */
#mobile-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  opacity: 1;
  position: absolute;
  width: 40%;
  height: 100vh;
  top: 0;
  right: 0;
  padding: 8em 2em 2em;
  background-color: var(--primary-color-500);
  box-shadow: -0.3em 0 5em rgba(16, 24, 40, 0.4);
  transform: translateX(200%);
  transition: none;
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 60;
  display: none;
}
#mobile-links.show {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}
#mobile-links .menu-link {
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: left;
  color: var(--white);
  margin-bottom: 0.2em;
  transition: var(--transition);
}
#mobile-links div {
  display: flex;
  justify-content: start;
  align-items: center;
  grid-gap: 1em;
}
#mobile-links i {
  font-size: 1.8em;
  color: var(--white);
}
#mobile-links .theme-icon {
  display: block;
  height: 1.9em;
  padding: 0 0 2px !important;
}
.theme-icon-mobile {
  display: none;
}

/* Dark Theme - nav bg on scroll */
body.dark-theme .scrolled {
  transition: none;
  background-color: var(--transparent-100) !important;
}

/* Dark Theme - desktop theme button bg */
body.dark-theme #desktop-links img:hover {
  background-color: var(--transparent-60);
}

/* Dark Theme - desktop links on scroll*/
body.dark-theme #desktop-links a {
  color: var(--primary-color) !important;
}

/* Dark Theme - mobile nav bg on toggle */
body.dark-theme #mobile-links {
  background-color: #1F1C55;
}
body.dark-theme #mobile-links.show {
  background-color: #1F1C55;
}

/* Dark Theme - mobile toggle icon */
body.dark-theme .mobile-menu i {
  color: var(--primary-color);
}

/* Toggle Button */
.mobile-menu {
  display: none;
  padding: 4px 10px 2px;
  background-color: var(--transparent-40);
  border: var(--light-border);
  border-radius: 0.5em;
  z-index: 70;
  transition: var(--transition);
}
.mobile-menu:hover {
  filter: brightness(110%);
  /* outline: 2px solid var(--primary-color-500);
  outline-offset: 2px; */
  transition: var(--transition);
}
.mobile-menu.menu-open {
  background-color: var(--transparent-20);
  transition: var(--transition);
}
.mobile-menu i {
  font-size: 1.4em;
  color: var(--primary-color-500);
  transition: var(--transition);
}
.mobile-menu.menu-open i {
  color: var(--white);
}

/* Footer Aside - Easy Contact
------------------------------------ */
/* aside {
  position: fixed;
  z-index: 20;
  bottom: 1em;
  right: 1em;
  padding: 8px 18px 8px 20px;
  background-color: var(--light-bg-60);
  backdrop-filter: blur(20px);
  filter: brightness(0.9);
  border-radius: 3em;
}
aside a {
  font-size: 2em;
  font-weight: 600;
  color: var(--white);
} */

/* Work Page - Hero
------------------------------------ */
.name-title {
  display: flex;
  flex-wrap: wrap;
  gap: 0 1em;
}
.work-hero .button {
  font-family: "Bricolage Grotesque", sans-serif;
  font-size: 1.05em;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 12px 24px;
  border-radius: 3em;
  background-color: var(--primary-color-500);
  outline: 0;
  outline-offset: 0;
  color: var(--white);
  transition:
    background-color 0s,
    outline 0.3s ease-in-out,
    outline-offset 0.3s ease-in-out;
}
.work-hero .button:focus,
.work-hero .button:hover,
.work-hero .button:active {
  background-color: var(--primary-color-600);
  outline: 2px solid var(--primary-color-600);
  outline-offset: 2px;
  transition:
    background-color 0.3s ease-in-out,
    outline 0.3s ease-in-out,
    outline-offset 0.3s ease-in-out;
}

/* Dark Theme - home hero button */
body.dark-theme .work-hero .button {
  background-color: var(--secondary-color-500);
  color: #1E2871 !important;
  transition:
    background-color 0s,
    outline 0.3s ease-in-out,
    outline-offset 0.3s ease-in-out;
}
body.dark-theme .work-hero .button:focus,
body.dark-theme .work-hero .button:hover,
body.dark-theme .work-hero .button:active {
  background-color: var(--secondary-color-600);
  outline: 2px solid var(--secondary-color-600);
  outline-offset: 2px;
  color: #1F1C55 !important;
  transition:
    background-color 0.3s ease-in-out,
    outline 0.3s ease-in-out,
    outline-offset 0.3s ease-in-out;
}

/* Work Page - Sections
------------------------------------ */
.work-sections {
  display: grid;
  grid-gap: 10em;
}

/* Work Cards Container
------------------------------------ */
.work-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 2em;
  width: 100%;
  position: relative; /* for the arrow icon */
}

/* Individual Work Cards
------------------------------------ */
.work-cards a {
  display: flex;
  overflow: hidden;
  position: relative; /* for the arrow icon */
  text-decoration: none;
  transition: 
    transform 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
}
.work-cards a:hover {
  transform: translateY(-0.8em);
  box-shadow: var(--shadow);
  opacity: 1;
  transition: 
    transform 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
}
.work-cards a article {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: stretch;
}

/* Individual Work Cards - Card Arrow
------------------------------------ */
.work-cards a::after {
  content: '';
  position: absolute;
  top: 2.5em;
  right: 2em;
  height: 80px;
  width: 80px;
  background: no-repeat url('/src/assets/icons/arrow.svg');
  background-size: contain;
  z-index: 1;
  opacity: 0; /* hidden on default */
  transition: opacity 0.3s ease;
}
.work-cards a:hover::after {
  opacity: 1; /* visible on hover */
  transition: opacity 0.3s ease;
}

/* Individual Work Cards - Details
------------------------------------ */
.work-card-details {
  flex-grow: 1;
  padding: 32px 24px;
}
.work-card-chip p {
  border: 1px solid;
  padding: 4px 12px 4px;
}

/* Individual Work Cards - Images
------------------------------------ */
.work-cards a img {
  align-self: flex-end;
  width: 100%;
  height: 400px;
  object-fit: cover;
  object-position: 100% 0;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  transition: var(--transition);
}
.work-cards a img[alt="R Architecture Website Design"] {
  /* object-position: 100% 25%; */
  object-position: 100% 70%;
}
.work-cards a:hover img {
  transform: scale(1.05);
  transition: var(--transition);
}

/* First Work Card
------------------------------------ */
.work-cards a:nth-child(1) {
  background-color: var(--card-bg-green);
}
/* Green Text */
.work-cards a:nth-child(1) .work-card-details,
.work-cards a:nth-child(1) .work-card-details p,
.work-cards a:nth-child(1) .work-card-chip p {
  color: var(--card-text-green);
}

/* Second Work Card
------------------------------------ */
.work-cards a:nth-child(2) {
  background-color: var(--card-bg-blue);
}
/* Brown Text */
.work-cards a:nth-child(2) .work-card-details,
.work-cards a:nth-child(2) .work-card-details p,
.work-cards a:nth-child(2) .work-card-chip p {
  color: var(--primary-color-500);
}

/* Third Work Card
------------------------------------ */
.work-cards a:nth-child(3) {
  background-color: var(--card-bg-orange);
}
/* White Text */
.work-cards a:nth-child(3) .work-card-details,
.work-cards a:nth-child(3) .work-card-details p,
.work-cards a:nth-child(3) .work-card-chip p {
  color: var(--card-text-orange);
}

/* Fourth Work Card
------------------------------------ */
.work-cards a:nth-child(4) {
  background-color: var(--card-bg-brown);
}
/* Purple */
.work-cards a:nth-child(4) .work-card-details,
.work-cards a:nth-child(4) .work-card-details p,
.work-cards a:nth-child(4) .work-card-chip p {
  color: var(--card-text-brown);
}

/* Fifth Work Card
------------------------------------ */
.work-cards a:nth-child(5) {
  background-color: var(--card-bg-yellow);
}
/* Purple */
.work-cards a:nth-child(5) .work-card-details,
.work-cards a:nth-child(5) .work-card-details p,
.work-cards a:nth-child(5) .work-card-chip p {
  color: var(--card-text-yellow);
}

/* Sixth Work Card
------------------------------------ */
.work-cards a:nth-child(6) {
  background-color: var(--card-bg-purple);
}
/* Purple */
.work-cards a:nth-child(6) .work-card-details,
.work-cards a:nth-child(6) .work-card-details p,
.work-cards a:nth-child(6) .work-card-chip p {
  color: var(--card-text-purple);
}

/* Dark Theme - work cards */
body.dark-theme .work-cards .work-card-details, 
body.dark-theme .work-cards a:nth-child(1) .work-card-details p,
body.dark-theme .work-cards a:nth-child(2) .work-card-details p,
body.dark-theme .work-cards a:nth-child(3) .work-card-details p,
body.dark-theme .work-cards a:nth-child(4) .work-card-details p,
body.dark-theme .work-cards a:nth-child(5) .work-card-details p,
body.dark-theme .work-cards a:nth-child(6) .work-card-details p,
body.dark-theme .work-cards .work-card-chip {
  color: #FFF !important;
}
body.dark-theme .work-cards a:hover {
  box-shadow: 0 19px 38px rgba(10, 13, 38, 0.3), 0 15px 12px rgba(10, 13, 38, 0.2);
}

/* Project Cards Container
------------------------------------ */
.project-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-template-rows: auto;
  grid-gap: 2em;
  text-decoration: none;
}

/* Individual Project Cards
------------------------------------ */
.project-cards a {
  display: flex;
  flex-direction: column;
  background-color: var(--transparent-20);
  backdrop-filter: blur(20px) saturate(1.2);
  border: var(--light-border);
  color: var(--primary-color-500);
  transition:
    transform 0.3s ease-in-out,
    background-color 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;
}
.project-cards a:hover {
  transform: translateY(-0.8em);
  background-color: var(--transparent-60);
  opacity: 1;
  transition:
    transform 0.3s ease-in-out,
    background-color 0.3s ease-in-out,
    opacity 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;
}
.project-cards a article {
  flex-grow: 1;
  padding: 32px 24px 16px;
}

/* Dark Theme - project cards */
body.dark-theme .project-cards a {
  color: rgba(255, 255, 255, 0.9);
}
body.dark-theme .project-cards a:hover {
  box-shadow: 0 19px 38px rgba(10, 13, 38, 0.1), 0 15px 12px rgba(10, 13, 38, 0.1);
}

/* Individual Project Cards - Tech Stack
------------------------------------ */
.project-icons {
  display: flex;
  align-items: flex-end;
  gap: 0.6em;
  padding: 0 24px 32px;
}
.project-icons img {
  height: 24px;
}

/* CTA - About
------------------------------------ */
.about-cta {
  display: flex;
  justify-content: center;
  padding: 4em 2em 4em 0;
}

/* CTA - Polaroids
------------------------------------ */
/* Mobile Polaroid */
.mobile-polaroids {
  display: none;
}
/* Both Polaroids */
.polaroids {
  display: flex;
  width: 60%;
}
.polaroids figure {
  position: relative;
  margin: 0;
  padding: 0;
}
.polaroids figure:hover {
  z-index: 1;
}
.polaroids figure img {
  max-width: 100%;
  height: auto;
  box-shadow: var(--shadow);
  transition: transform 0.3s ease-in-out;
}
.polaroids figure:nth-of-type(2) img {
  max-width: 98%;
}
.polaroids figure:hover img {
  transform: scale(1.05) rotate(3deg);
  transition: transform 0.3s ease-in-out;
}

/* Polaroid Captions */
.polaroids figcaption {
  font-family: "Homemade Apple", sans-serif;
  font-size: 1em;
  text-align: center;
  line-height: 1.2;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-left: -0.7em;
  padding-bottom: 0.7em;
  opacity: 0; /* hidden on default */
  color: var(--primary-color-500);
  transition: opacity 0.2s ease-out; /* quickly hide */
}
.polaroids figure:nth-of-type(2) figcaption {
  padding-bottom: 1em;
}
.polaroids figure:hover figcaption {
  opacity: 1; /* visible on hover */
  transition: opacity 0.4s 0.2s ease; /* short delay before showing */
}

/* Dark Theme - captions */
body.dark-theme .polaroids figcaption {
  color: #1E2871;
}

/* Polaroid - Beach */
.polaroids figure:nth-of-type(1) {
  transform: rotate(-7deg);
}
.polaroids figure:nth-of-type(1) figcaption {
  transform: rotate(3deg);
}

/* Polaroid - Me */
.polaroids figure:nth-of-type(2) {
  transform: rotate(4deg) translateY(2em);
}
.polaroids figure:nth-of-type(2) figcaption {
  transform: rotate(3deg);
}

/* CTA & About Email - Link
------------------------------------ */
.cta-link {
  align-self: center;
  margin-right: 1em;
}

.about-email {
  position: relative;
}

.about-email a {
  position: absolute;
}

.cta-link,
.about-email a {
  border-bottom: 0;
  line-height: 1.1;
  color: var(--primary-color-500);
  transition: transform 0.2s ease-in-out, border-bottom 0.5s ease;
}
.cta-link:hover,
.about-email a:hover {
  transform: translateY(-0.2em);
  border-bottom: 4px solid var(--primary-color-500);
  transition: transform 0.2s ease-in-out, border-bottom 0.5s ease;
}

/* Dark Theme - cta & email link */
body.dark-theme .cta-link,
body.dark-theme .about-email a {
  color: var(--primary-color);
}
/* Dark Theme - cta & email link */
body.dark-theme .cta-link:hover,
body.dark-theme .about-email a:hover {
  border-bottom: 4px solid var(--primary-color);
}

/* About email on mobile */
.mobile-contact {
  display: none;
}

/* Archive
------------------------------------ */
.design-project-content {
  display: grid;
  grid-gap: 6em;
}
.archive-items {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 4em;
  transition: grid-template-columns 0.3s ease-in-out;
}
.archive-images {
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em; */
  transition: grid-template-columns 0.3s ease-in-out;
  /* order: 1; */
  align-content: center;
}
/* .archive-items:last-of-type .archive-images {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
  transition: grid-template-columns 0.3s ease-in-out;
  order: 1;
} */

/* Archive - Hide Amari logo */
/* .archive-items:last-of-type .archive-images img:last-of-type {
  display: none;
} */
/* .archive-items:last-of-type img {
  border-radius: 1em;
} */

/* Individual Image */
.archive-images img {
  max-width: 100%;
  height: auto;
}

/* AMI Carousel
------------------------------------ */
.archive-buttons {
  display: flex;
  justify-content: space-between;
}
/* image background */
.ami-carousel,
.os-carousel {
  background-color: var(--transparent-40);
  border-radius: 1em;
}
/* Next image on click */
.ami-carousel img,
.os-carousel img {
  cursor: pointer;
}

/* Other Work
------------------------------------ */
.logo-items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
}
.logo-items img {
  width: 100%;
  border-radius: 0.8em;
}

/* About 
------------------------------------ */
/* About - 2 Columns  */
.about-hero,
.about-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2em;
}

/* About - Image  */
.about-hero img {
  max-width: 560px;
  height: auto;
  justify-self: center;
}

/* About Competencies - Article */
.competencies h5 {
  font-size: 1em;
  font-weight: 700;
}
.competencies p {
  margin-bottom: 1.6em;
}

/* About Skills & Technologies - Grid Layout */
/* .technologies div {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75em;
  margin-bottom: 1em;
}
.technologies img {
  width: 48px;
  border-radius: 8px;
} */

.technologies {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.technologies-mobile {
  display: none;
}

/* About Experience & Education - Grid Layout */
.experience,
.education {
  display: grid;
  grid-template-columns: 1fr;
}
.experience-details,
.education-details {
  display: grid;
  padding: 1em 0;
  border-bottom: 1px solid var(--primary-color-500);
}

/* Dark Theme */
body.dark-theme .experience-details,
body.dark-theme .education-details {
  border-bottom: 1px solid var(--primary-color);
}

.experience-details {
  grid-template-columns: 1fr 1fr;
}

.education-details {
  grid-template-columns: 3fr 1fr;
}

/* About Experience & Education - Year Alignment */
.experience-details .content-year,
.education-details .content-info {
  justify-self: flex-end;
  text-align: right;
}
/* About Experience & Education - No Border */
.experience-details:last-of-type,
.education-details:last-of-type {
  padding-bottom: 0;
  border: 0;
}

/* Dark Theme */
body.dark-theme .experience-details:last-of-type,
body.dark-theme .education-details:last-of-type {
  border: 0;
}

/* Hide Education Span with Comma */
.education span:nth-last-of-type(1) {
  display: none;
}

/* Recommendation
------------------------------------ */
/* Carousel */
.reco-carousel {
  position: relative;
}
.reco-carousel figure {
  display: flex;
  align-items: center;
  gap: 1em;
}
.reco-carousel img {
  max-height: 50px;
  border-radius: 50%;
}
.reco-carousel figcaption p {
  margin: 0;
}

.carousel-container {
  position: relative;
  width: 100%;
  min-height: 300px;
}

.carousel-content {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0; /* Initially hide all content */
  height: 0;
  overflow: hidden;
  transition: opacity 1s ease-in-out; /* Smooth transition when changing content */
}

.carousel-content.active-content {
  opacity: 1; /* Show active content */
  height: auto;
}

.carousel-arrows {
  position: absolute;
  box-sizing: border-box;
  bottom: 0.7em;
  right: 0;
}

.arrow {
  font-size: 16px;
  border: 0;
  border-radius: 3em;
  background-color: var(--primary-color-500);
  outline: 0;
  outline-offset: 0;
  padding: 6px 9px 4px;
  transition: none;
}

.arrow:focus,
.arrow:hover,
.arrow:active {
  background-color: var(--primary-color-600);
  outline: 2px solid var(--primary-color-600);
  outline-offset: 2px;
  transition: none;
}

.arrow-prev {
  margin-right: 12px;
}

.arrow i {
  color: var(--white);
}

blockquote {
  min-height: 300px;
  margin-bottom: 0 !important;
}

blockquote p:first-child {
  max-width: 700px;
  min-height: 220px;
  font-family: "Bricolage Grotesque", sans-serif;
  font-size: 1.3em;
  margin-bottom: 1.2em;
}

blockquote figcaption {
  font-style: normal;
}

blockquote figcaption p {
  margin-top: 0.1em;
}

blockquote figcaption a {
  font-weight: 600;
  color: var(--primary-color-500);
  margin-bottom: 0.5em;
  border-bottom: 1px solid var(--transparent-100);
  transition: border-bottom 0.3s ease;
}

blockquote figcaption a:hover {
  border-bottom: 1px solid var(--primary-color-500);
}

/* Dark Theme - recommendation links */
body.dark-theme .arrow {
  background-color: var(--secondary-color-500);
  transition:
    background-color 0s,
    outline 0.3s ease-in-out,
    outline-offset 0.3s ease-in-out;
}
body.dark-theme .arrow:focus,
body.dark-theme .arrow:hover,
body.dark-theme .arrow:active {
  background-color: var(--secondary-color-600);
  outline: 2px solid var(--secondary-color-600);
  transition:
    background-color 0s,
    outline 0.3s ease-in-out,
    outline-offset 0.3s ease-in-out;
}
body.dark-theme .arrow i {
  color: #1E2871;
}
body.dark-theme blockquote cite a:hover {
  border-bottom: 1px solid var(--primary-color);
}

/* Footer
------------------------------------ */
footer {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 99;
  /* border-bottom: 6px solid var(--primary-color-500); */
  margin-top: 5em;
  padding: 1.4em 2em;
}

footer div {
  display: flex;
  align-items: center;
  grid-gap: 1em;
}

/* LinkedIn Logo Size */
footer i {
  font-size: 1.8em;
  color: var(--primary-color-500);
}

/* Dark Theme - LinkedIn Logo */
body.dark-theme footer i {
  color: var(--primary-color);
}

footer p:nth-of-type(1) {
  margin-bottom: 0;
}

footer a:first-of-type {
  color: var(--primary-color-500);
  font-weight: 600;
  transition: transform 0.3s ease-in-out;
}

footer a:hover {
  transform: translateY(-0.3em);
  transition: transform 0.3s ease-in-out;
}

/* React Icon */
footer p img {
  padding: 0 0.3em 0.2em;
  height: 24px;
}

/* Theme Toggle - hide on desktop */
footer .theme-icon {
  display: none;
}

/* Other Text Styles
----------------------------------- */
.work-hero p {
  max-width: 900px;
  margin-bottom: 2em;
}

.name-title h1 {
  letter-spacing: 2px;
  margin: 2px 0 0;
}

.main-title {
  max-width: 860px;
}

.archive-hero h1 {
  max-width: 500px;
  margin: 0;
}

.work-card-details p {
  max-width: 380px;
  margin: 0;
}

/* Chips */
.work-card-chip,
.archive-chip {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  white-space: pre-line;
}

/* Uppercase */
.name-title h1,
.status,
.experience small,
.education small,
.work-card-chip,
.archive-chip {
  text-transform: uppercase;
}

/* Onest Semi Bold */
.nav-link,
.name-title h1,
.featured-work h2,
.personal-projects h2,
.design-projects h2,
.archive-hero h2,
.about-hero h2 {
  font-weight: 600;
}

/* Onest Bold */
.experience-details div p:nth-of-type(1),
.education-details div p:nth-of-type(1) {
  font-weight: 700;
}

/* Bricolage Extra Bold */
#mobile-links .menu-link,
.main-title,
.project-card-details h3,
.work-card-details h3,
.cta-link,
.archive-hero h1,
.archive-items h3,
.about-hero h1,
.about-content h3,
.about-email a,
.mobile-contact a {
  font-family: "Bricolage Grotesque", sans-serif;
  font-weight: 800;
}

/* H2 With Sparkles */
.featured-work h2,
.personal-projects h2,
.design-projects h2,
.archive-hero h2,
.about-hero h2 {
  margin: 2px 0 0;
}

/* No Margin */
.archive-chip p,
.work-card-chip p,
.project-card-details h3,
.project-cards p,
.technologies p {
  margin: 0;
}

.work-cards a,
.project-cards a,
.archive-images img {
  border-radius: 1.3em;
}

/* Text Font Sizes
----------------------------------- */
/* Work Hero */
.main-title {
  font-size: 2.9em;
}
/* For Dev & Design */
.main-title {
  font-size: 2.7em;
}

/* Inner Pages Hero */
.archive-hero h1,
.about-hero h1 {
  font-size: 2.6em;
}

/* CTA Link,
About Email Link */
.cta-link,
.about-email a {
  font-size: 2.3em;
}

/* Mobile Links,
My Name/Position */
#mobile-links .menu-link {
  font-size: 2em;
}

/* Featured Work Title */
.work-card-details h3 {
  font-size: 1.7em;
}

/* For Dev & Design */
.name-title h1 {
  font-size: 1.7em;
  letter-spacing: 1.5px;
}

/* About Section Titles */
.about-content h3 {
  font-size: 1.6em;
}

/* Archive Project Title,
Project Card Title */
.archive-items h3,
.project-card-details h3 {
  font-size: 1.3em;
}

/* Competencies Numbers,
Section Titles with Sparkles, */
.about-hero h2,
.archive-hero h2,
.featured-work h2,
.design-projects h2,
.personal-projects h2 {
  font-size: 1.3em;
}

/* Space between sparkle and text */
.archive-hero div,
.about-hero div,
.featured-work header,
.personal-projects header,
.design-projects header {
  gap: 1em;
}

/* Hero Paragraphs */
.about-hero p,
.work-hero p {
  font-size: 1.2em;
}

/* Desktop Nav Links,
Footer Text,
About School & Company Names,
About Competencies & Skills,
Featured Work Description,
Project Description,
Archive Description */
#desktop-links .menu-link,
footer p,
.experience-details div,
.education-details div,
.technologies p,
.competencies p,
.work-card-details p,
.project-cards p:nth-child(2) {
  font-size: 1em;
}

/* Status, Chips & Small */
.status,
.experience small,
.education small,
.work-card-chip,
.archive-chip {
  font-size: 0.8em;
}
.work-card-chip p,
.archive-chip p {
  border: 1px solid;
  border-radius: 3em;
  padding: 4px 12px 2.5px;
}
.status svg {
  fill: var(--yellow);
}

/* Archive - get in touch */
.archive-chip {
  margin-bottom: 2em;
}
.archive-items h3 {
  margin-bottom: 1em;
}
.archive-items article a {
  font-size: 0.85em;
  font-style: italic;
  color: var(--primary-color-500);
  margin-top: 1em;
  border-bottom: 1px solid var(--transparent-100);
  transition: border-bottom 0.3s ease;
}
.archive-items article a:hover {
  border-bottom: 1px solid var(--primary-color-500);
}
.archive-items article p a {
  display: none;
}
body.dark-theme .archive-items article a:hover {
  border-bottom: 1px solid var(--primary-color);
}

.archive-items article {
  align-content: center;
}
/* .archive-items:nth-of-type(2) {
  grid-template-columns: 2fr 1fr;
}
.archive-items:nth-of-type(2) article {
  order: 2;
}
.archive-items:nth-of-type(2) .archive-images {
  order: 1;
} */

/* Large Screens 
------------------------------------------------------------- */
@media only screen and (max-width: 1200px) {
  main {
    margin: 0 1em;
  }

  /* Blur */
  .blur:nth-of-type(1),
  .blur:nth-of-type(2),
  .blur:nth-of-type(3) {
    transform: scale(1.5);
    transition: transform 0.3s ease-in-out;
  }

  /* Nav bar */
  .fixed-nav nav > div:nth-last-of-type(1) {
    padding: 1.4em 1em;
  }

  /* Recommendation */
  blockquote p:first-child {
    font-size: 1.2em;
  }

  /* Footer */
  footer {
    padding: 1.4em 1em;
  }
}

@media only screen and (max-width: 1024px) {
  /* Home - Featured Work
  ----------------------------------- */
  /* Image - Make Shorter */
  .work-cards a img {
    height: 300px;
  }

  /* About Hero - Swap Order
  ----------------------------------- */
  .about-hero {
    grid-template-columns: 1fr 2fr;
    transition: var(--transition);
  }
  .about-hero article {
    order: 2;
  }
  .about-hero img:last-child {
    order: 1;
    width: 100%;
  }

  /* About Content - 1:2 Column
  ----------------------------------- */
  .about-content {
    grid-template-columns: 1fr 2fr;
  }

  /* CTA - figcaption */
  .polaroids figure:nth-of-type(1) figcaption {
    padding-bottom: 0.5em;
  }
  .polaroids figure:nth-of-type(2) figcaption {
    padding-bottom: 0.7em;
  }

  /* Recommendation */
  blockquote p:first-child {
    font-size: 1.3em;
  }
}

/* Medium Screens
------------------------------------------------------------- */
@media only screen and (max-width: 997px) {
  main {
    padding-top: 10em;
  }

  /* Blur */
  .blur:nth-of-type(1),
  .blur:nth-of-type(2),
  .blur:nth-of-type(3) {
    transform: scale(1, 1.4) translateX(-30%);
  }

  /* Work Page - Grid gap */
  .work-cards,
  .project-cards {
    grid-gap: 1em;
  }

  /* Cards - Mobile "Hover"
  ----------------------------------- */
  /* Image - Make Shorter */
  .work-cards a img {
    height: auto;
    object-position: initial;
  }
  /* Arrow - Hide */
  .work-cards a::after {
    display: none;
  }
  .work-cards a.touch-interaction::after {
    opacity: 1;
  }

  /* Card Transition */
  .work-cards a:hover {
    transform: scale(101%) translateY(0);
    box-shadow: var(--shadow);
    opacity: 1;
    transition: 
      transform 0.3s ease-in-out,
      box-shadow 0.3s ease-in-out;
  }
  .project-cards a:hover {
    transform: scale(101%) translateY(0);
    background-color: var(--transparent-60);
    box-shadow: var(--shadow);
    opacity: 1;
    transition: 
      transform 0.3s ease-in-out,
      background-color 0.3s ease-in-out,
      box-shadow 0.3s ease-in-out;
  }

  /* Work Page - Sections
  ------------------------------------*/
  .work-sections {
    grid-gap: 8em;
  }

  /* Project Cards Size
  ----------------------------------- */
  .project-cards a article,
  .project-icons {
    padding: 1em;
  }
  .project-icons {
    padding-top: 0;
  }

  /* Text Font Sizes
  ----------------------------------- */
  .project-card-details h3 {
    font-size: 1.2em;
  }

  /* Polaroids
  ----------------------------------- */
  /* Reset */
  .about-cta figure,
  .about-cta figure:hover,
  .polaroids figure:hover img,
  .about-cta figure figcaption {
    transform: none;
  }

  /* No Transition */
  .about-cta figcaption {
    padding-bottom: 0.8em;
    margin-left: -0.2em;
    opacity: 1;
  }
  .about-cta figure:nth-of-type(1) {
    transform: rotate(-7deg);
    transition: transform 0.3s ease-in-out;
  }
  .about-cta figure:nth-of-type(2) {
    transform: rotate(4deg) translateY(1em);
    transition: transform 0.3s ease-in-out;
  }
  .about-cta figure:nth-of-type(1):hover {
    transform: rotate(-10deg);
    transition: transform 0.3s ease-in-out;
  }
  .about-cta figure:nth-of-type(2):hover {
    transform: rotate(9deg) translateY(1em);
    transition: transform 0.3s ease-in-out;
  }

  /* Both Polaroid Beach Captions */
  .polaroids figure:nth-of-type(1) figcaption,
  .polaroids figure:nth-of-type(2) figcaption {
    font-size: 0.8em;
    transform: rotate(0);
  }
  .polaroids figure:nth-of-type(1) figcaption {
    padding-bottom: 1em;
  }
  .polaroids figure:nth-of-type(2) figcaption {
    padding-bottom: 1.3em;
  }

  /* Archive
  ----------------------------------- */
  .archive-items {
    grid-template-columns: 3fr 2fr;
    grid-gap: 1.5em;
    margin-right: 1.5em;
  }
  .archive-images {
    grid-template-columns: 1fr 1fr 1fr;
    transition: grid-template-columns 0.3s ease-in-out;
  }

  /* 4th image of archive items */
  .archive-items:nth-of-type(1) .archive-images img:nth-last-of-type(4),
  .archive-items:nth-of-type(2) .archive-images img:nth-last-of-type(4),
  .archive-items:nth-of-type(3) .archive-images img:nth-last-of-type(4) {
    display: none;
  }
  
  /* Archive Order */
  .archive-items article {
    order: 2;
  }

  /* CTA
  ----------------------------------- */
  .cta-link {
    font-size: 2em;
  }

  /* Recommendation
  ----------------------------------- */
  .carousel-arrows {
    bottom: 0.8em;
  }

  /* Archive - get in touch */
  .archive-items article > a {
    display: none;
  }

  .archive-items h3 {
    margin-bottom: 0.5em;
  }

  .archive-items article a {
    border-bottom: 1px solid var(--primary-color-500);
    transition: none;
  }
  body.dark-theme .archive-items article a {
    border-bottom: 1px solid var(--primary-color);
    transition: none;
  }
}

/* Small Screens
------------------------------------------------------------- */
@media only screen and (max-width: 768px) {
  main {
    transition: none;
  }

  /* Blur */
  .blur:nth-of-type(1),
  .blur:nth-of-type(2),
  .blur:nth-of-type(3) {
    transform: scale(1.6, 2);
    transition: none;
  }

  /* Navigation
  ----------------------------------- */
  .logo,
  .logo img {
    height: 50px;
  }
  #desktop-links {
    display: none;
  }
  .mobile-menu {
    display: block;
    padding: 4px 14px 2px;
  }
  #mobile-links {
    display: block;
  }
  .theme-icon-mobile {
    display: block;
    cursor: pointer;
    border-radius: 3em;
    align-self: center;
    padding: 10px !important;
    height: 40px;
    transition: none;
  }

  /* Home Hero - disable transitions
  ----------------------------------- */
  .name-title h1,
  .main-title,
  .work-hero p {
    transition: none;
  }

  /* About Hero - disable transitions
  ----------------------------------- */
  .about-hero img:nth-last-child(),
  .about-hero article> div,
  .about-hero article > h1,
  .about-hero article > p,
  .about-content {
    transition: none;
  }

  /* Archive Hero - disable transitions
  ----------------------------------- */
  .archive-hero h1,
  .archive-hero > div,
  .archive-hero h2,
  .archive-items article > h3,
  .archive-items article > p,
  .archive-chip,
  .archive-images {
    transition: none;
  }

  /* Featured Work - 1 Column
  ----------------------------------- */
  .work-cards img {
    height: 50%;
    max-height: 270px;
  }
  .work-card-details {
    padding: 1.6em 1.3em;
  }
  
  /* Personal Projects - Smaller Icons
  ----------------------------------- */
  .project-cards .project-card-details img {
    height: 32px;
  }

  /* Images and Cards - Border Radius
  ----------------------------------- */
  .work-cards a,
  .project-cards a,
  .archive-images img {
    border-radius: 1em;
  }

  /* CTA - Centered
  ----------------------------------- */
  .about-cta {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 0;
  }
  .cta-link {
    margin-right: 0;
    order: 2;
  }
  .cta-link,
  .about-email a {
    line-height: 1;
    border-bottom: 4px solid var(--primary-color-500);
    transition: none;
  }
  .cta-link:hover,
  .about-email a:hover {
    transform: translateY(0);
    border-bottom: 4px solid var(--primary-color-500);
    transition: none;
  }

  /* Dark Theme */
  body.dark-theme .cta-link,
  body.dark-theme .about-email a {
    border-bottom: 4px solid var(--primary-color);
    transition: none;
  }
  body.dark-theme .cta-link:hover,
  body.dark-theme .about-email a:hover {
    transform: translateY(0);
    border-bottom: 4px solid var(--primary-color);
    transition: none;
  }

  /* Mobile Polaroid */
  .mobile-polaroids {
    display: block;
    width: 70%;
  }
  .polaroids {
    display: none;
  }

  /* Archive
  ----------------------------------- */
  .archive-items {
    grid-template-columns: 1fr;
    grid-gap: 1em;
    margin-right: 0;
  }
  .archive-items article {
    width: 100%;
    display: grid;
  }
  .archive-chip,
  .archive-items article h3 {
    width: 100%;
  }
  .archive-items article h3 {
    order: 2;
    margin-top: 2em;
  }
  .archive-items article p {
    order: 3;
  }
  .archive-chip {
    order: 4;
  }
  .archive-buttons {
    order: 1;
    justify-content: center;
  }
  .archive-buttons a {
    display: none;
  }
  .archive-items article p a {
    display: inline;
    font-size: 1em;
  }

  /* About Hero - Swap Order
  ----------------------------------- */
  .about-hero {
    grid-template-columns: 1fr;
    grid-gap: 0
  }
  .about-hero article {
    order: 2;
  }
  .about-hero img:last-child {
    order: 1;
    width: 100%;
    margin-top: -5em;
  }
  .about-content {
    grid-template-columns: 1fr 3fr;
  }

  /* About
  ----------------------------------- */
  /* Recommendation */
  blockquote p:first-child {
    font-size: 1.1em;
  }

  blockquote cite a {
    border-bottom: 1px solid var(--primary-color-500);
  }

  /* Dark Theme */
  body.dark-theme blockquote cite a {
    border-bottom: 1px solid var(--primary-color);
  }

  /* .carousel-container {
    position: relative;
    width: 100%;
    min-height: 300px;
  } */
  
  .carousel-content {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0; /* Initially hide all content */
    height: 0;
    display: none;
    transition: none; /* Smooth transition when changing content */
  }
  
  .carousel-content.active-content {
    opacity: 1; /* Show active content */
    height: auto;
    display: block;
  }

  .carousel-arrows {
    bottom: 1em;
  }

  .arrow {
    background-color: var(--primary-color-500);
    outline: 0;
    outline-offset: 0;
    color: var(--off-white);
    transition: none;
  }

  .arrow:hover {
    background-color: var(--primary-color-500);
    outline: 0;
    outline-offset: 0;
    transition: none;
  }
  .arrow:focus,
  .arrow:active {
    background-color: var(--primary-color-600);
    outline: 0;
    outline-offset: 0;
    transition: none;
  }

  /* Dark Theme */
  body.dark-theme .arrow:hover,
  body.dark-theme .arrow:focus,
  body.dark-theme .arrow:active {
    background-color: var(--secondary-color-600);
    outline: 0;
    outline-offset: 0;
    transition: none;
  }

  .arrow-prev {
    margin-right: 12px;
  }

  .arrow i {
    color: var(--off-white);
  }

  
  /* Text Other Styles
  ----------------------------------- */
  .work-hero {
    text-align: center;
  }
  
  .name-title {
    justify-content: center;
  }

  .work-hero p {
    max-width: 90%;
    margin: auto;
  }

  /* Chip Spacing */
  .work-card-chip,
  .archive-chip {
    gap: 0.8em 0.6em;
  }

  /* Feature Work Desc Full Width */
  .work-card-details p {
    max-width: 100%;
  }

  .project-card-details h3 {
    margin-bottom: 0.4em;
  }

  .about-content h3 {
    font-size: 1.1em;
  }

  /* Text Font Sizes
  ----------------------------------- */
  .main-title {
    font-size: 2.6em;
  }
  #mobile-links .menu-link,
  .work-card-details h3 {
    font-size: 1.8em
  }
  .about-hero p,
  .work-hero p {
    font-size: 1.1em;
  }
  .status,
  .experience small,
  .education small {
    font-size: 0.7em;
  }
  .work-card-chip,
  .archive-chip {
    font-size: 0.7em;
  }
  /* About Email Link */
  .cta-link,
  .about-email a {
    font-size: 1.4em;
    border-bottom: 4px solid var(--primary-color-500);
  }

    /* CTA
  ----------------------------------- */
  .cta-link {
    font-size: 1.8em;
  }

  /* Hero Button
  ----------------------------------- */

  .work-hero .button {
    background-color: var(--primary-color-500);
    outline: 0;
    outline-offset: 0;
    color: var(--off-white);
    transition: none;
  }
  .work-hero .button:hover {
    background-color: var(--primary-color-500);
    outline: 0;
    outline-offset: 0;
    transition: none;
  }
  .work-hero .button:focus,
  .work-hero .button:active {
    background-color: var(--primary-color-600);
    outline: 0;
    outline-offset: 0;
    transition: none;
  }

  /* Dark Theme */
  body.dark-theme .work-hero .button:hover,
  body.dark-theme .work-hero .button:focus,
  body.dark-theme .work-hero .button:active {
    background-color: var(--secondary-color-600);
    outline: 0;
    outline-offset: 0;
    transition: none;
  }

  .work-hero p {
    margin-bottom: 2em;
  }

  /* Footer Changes */
  footer {
    flex-direction: column;
    grid-gap: 0.6em;
  }
  footer div:nth-of-type(2) {
    flex-direction: column;
    grid-gap: 0.6em;
  }
  footer a:hover {
    transform: translateY(0);
  }
  /* Theme Toggle - hide on desktop */
  footer .theme-icon {
    display: block;
    height: 1.9em;
    padding: 0 0 2px !important;
  }
  /* Footer Email */
  footer a:first-of-type {
    font-family: "Bricolage Grotesque", sans-serif;
    font-size: 1.2em;
    font-weight: 800;
  }
}

/* Mobile
------------------------------------------------------------- */
@media only screen and (max-width: 480px) {
  main {
    padding-top: 8em;
    gap: 5em;
  }

  /* Blur */
  .blur:nth-of-type(1) {
    transform: scale(2) translate(20%, -40%);
  }
  .blur:nth-of-type(2) {
    transform: scale(1.6, 1) translate(-50%, -20%);
    filter: blur(4em);
  }
  .blur:nth-of-type(3) {
    transform: scale(1, 1) translate(5%, -50%);
    filter: blur(4em);
  }

  /* Sparkles
  ----------------------------------- */
  img[alt="Sparkle"]{
    width: 17px;
  }
  .name-title img[alt="Sparkle"]{
    width: 18px;
  }

  /* Navigation
  ----------------------------------- */
  .logo,
  .logo img {
    height: 40px;
  }
  .fixed-nav nav > div:nth-last-of-type(1){
    padding: 1em;
  }
  .mobile-menu {
    padding: 4px 12px 2px;
  }
  #mobile-links {
    width: 60%;
  }

  /* Work Cards
  ----------------------------------- */
  .work-cards {
    grid-template-columns: 1fr;
  }
  .work-card-details {
    padding: 1.2em 1em;
  }

  /* Project Cards - Hide on mobile
  ----------------------------------- */
  .personal-projects {
    display: none;
  }
  .project-cards,
  .archive-images {
    grid-gap: 0.6em
  }
    /* .project-cards,
  .archive-images,
  .archive-items:last-of-type .archive-images {
    grid-gap: 0.6em
  } */

  /* Archive
  ----------------------------------- */
  .archive-images {
    grid-template-columns: 1fr 1fr;
  }

  /* 4th image of archive items */
  .archive-items:nth-of-type(1) .archive-images img:nth-last-of-type(4),
  .archive-items:nth-of-type(2) .archive-images img:nth-last-of-type(4),
  .archive-items:nth-of-type(3) .archive-images img:nth-last-of-type(4) {
    display: block;
  }

  .archive-items:last-of-type img {
    border-radius: 0.7em;
  }

  /* About
  ----------------------------------- */
  .experience-details,
  .education-details {
    padding: 1em 0;
  }

  .experience-details .content-name,
  .education-details .content-name {
    grid-area: name;
  }

  .education-details .content-info {
    margin-bottom: 0;
    grid-area: info;
  }
  .experience-details .content-year {
    margin-bottom: 0;
    grid-area: year;
  }
  .about-content {
    grid-template-columns: 1fr;
  }
  .about-content:nth-of-type(5) .experience-details {
    grid-template-columns: 2fr 1fr;
    grid-template-areas: "name year";
  }

  .about-content:nth-of-type(6) .education-details {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "name"
      "info";
  }

  .education-details .content-info {
    justify-self: flex-start;
    text-align: left;
  }
  /* Show Education Span with Comma */
  .education span:nth-last-of-type(1) {
    display: block;
  }
  /* Hide Education Span with two lines */
  .education span:nth-last-of-type(2) {
    display: none;
  }
  /* Remove space on top of Experience and Education details */
  .experience-details:nth-of-type(1),
  .education-details:nth-of-type(1) {
    padding-top: 0;
  } 

  .about-hero img:last-child {
    margin-top: -3em;
  }

  /* Recommendation */
  .carousel-arrows {
    top: -3.4em;
  }
  .arrow {
    font-size: 18px;
    padding: 6px 10px 4px;
    transition: none;
  }

  /* About Contact Email */
  .about-email {
    display: none;
    position: static;
  }
 
  /* CTA
  ----------------------------------- */
  .mobile-polaroids {
    width: 95%;
  }

  /* Text Other Styles
  ----------------------------------- */
  .work-hero {
    text-align: left;
  }
  .name-title {
    justify-content: start;
  }

  .main-title {
    margin-bottom: 0;
  }
  .work-hero p {
    max-width: 100%;
  }

  /* Featured Work Paragraph and Image */
  .work-card-details > p {
    margin-bottom: 0.8em;
  }
  .work-cards a img {
    object-position: 100% 10%;
  }
  .work-cards a img[alt="R Architecture Website Design"] {
    object-position: 100% 40%;
  }
  .work-cards a img[alt="Changing Digital Website Design"] {
    object-position: 100% 40%;
  }

  .about-content {
    grid-gap: 1em;
  } 

  .about-content h3 {
    margin-top: 0 !important;
  }

  /* About email on mobile */
  .about-content:last-of-type {
    display: none;
  } 
  .mobile-contact {
    display: block;
    font-size: 1.6em;
    text-align: center;
  }
  .mobile-contact a {
    color: var(--primary-color-500);
    border-bottom: 4px solid var(--primary-color-500);
  }

  /* Dark Theme */
  body.dark-theme .mobile-contact a {
    color: var(--primary-color);
    border-bottom: 4px solid var(--primary-color);
  }

  /* Text Other Text Styles
  ----------------------------------- */
  /* Mobile Links */
  #mobile-links .menu-link {
    margin-bottom: 0.5em;
  }
  /* .archive-items h3 {
    width: 100%;
  }
  .archive-items article > p {
    width: 100%;
  } */
  .competencies h5 {
    margin-bottom: 0.25em;
  }
  .competencies p {
    margin-bottom: 1.2em;
  }

  /* Text Font Sizes
  ----------------------------------- */
  /* Mobile Links */
  #mobile-links .menu-link {
    font-size: 2em;
  }

  /* Work Hero */
  .main-title {
    font-size: 1.8em;
  }

  /* Inner Pages Hero */
  .archive-hero h1,
  .about-hero h1 {
    font-size: 1.5em;
  }

  /* My Name/Position,
  Featured Work Title */
  .name-title h1,
  .work-card-details h3 {
    font-size: 1.4em;
    max-width: 260px; /* For Dev & Design */
  }

  /* Archive Project Title,
  About Section Titles */
  .archive-items h3,
  .about-content h3 {
    font-size: 1.2em;
  }

  /* Competencies Numbers,
  Section Titles with Sparkles */
  .about-hero h2,
  .archive-hero h2,
  .featured-work h2,
  .personal-projects h2,
  .design-projects h2,
  .other-projects h2 {
    font-size: 1.1em;
  }
    
  /* Hero Paragraphs */
  .competencies p,
  .about-hero p,
  .work-hero p {
    font-size: 1em;
  }

  /* Desktop Nav Links,
  Footer Text,
  About School & Company Names,
  About Skills,
  Featured Work Description,
  Project Description,
  Archive Description */
  #desktop-links .menu-link,
  footer p,
  .experience-details div,
  .education-details div,
  .technologies p,
  .work-card-details p,
  .project-cards p:nth-child(2),
  .archive-items article > p {
    font-size: 0.9em;
  }

  /* Hide 3 columns on mobile */
  .technologies {
    display: none;
  }
  
  .technologies-mobile {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .technologies-mobile div p {
    margin-bottom: 0;
  }

  /* Disable all hover effects on mobile */
  .work-cards a:hover {
    transform: scale(1);
    box-shadow: none;
  }
  .work-cards a:hover img {
    transform: scale(1);
  }
  .project-cards a:hover {
    transform: scale(1);
    background-color: var(--transparent-20);
    box-shadow: none;
  }
  .cta-link:hover,
  .about-email a:hover {
    border-bottom: 4px solid var(--primary-color-500);
  }

  /* Hero Button
  ----------------------------------- */
  .work-hero .button {
    font-size: 0.8em;
    padding: 16px 26px;
    color: var(--white);
    transition: none;
  }

  .featured-work header {
    margin-top: 1.8em;
  }

  /* Footer
  ----------------------------------- */
  footer {
    padding: 1em 1em 1.5em;
    gap: 0.8em;
  }
  footer p:nth-of-type(1) {
    text-align: center;
  }
}